import React, { useEffect, useState } from 'react';
import axios from 'axios';

const API_BASE_URL = 'https://dremerz.net/api'; // Replace with your API base URL

export default function ViewAttendance() {
  const [employeeData, setEmployeeData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchByName, setSearchByName] = useState('');
  const [searchById, setSearchById] = useState('');
  const [searchByPhone, setSearchByPhone] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const employeeResponse = await axios.get(`${API_BASE_URL}/employees/`);
        const attendanceResponse = await axios.get(`${API_BASE_URL}/employeeattendance/`);

        setEmployeeData(employeeResponse.data);
        setAttendanceData(attendanceResponse.data);
       
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data. Please try again.');
        setLoading(false);
      }
    };
    fetchData();
  }, []);



    // Function to calculate the number of present days for a specific employee
  
    const getPresentDays = (employeeId) => {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
    console.log(employeeId)
      return attendanceData.filter((data) => {
        const attendanceDate = new Date(data.date); // Assuming the date is stored in the 'date' field
        const attendanceMonth = attendanceDate.getMonth();
        const attendanceYear = attendanceDate.getFullYear();
    console.log(data.employeeid)
        return (
          data.employeeid === employeeId &&
          data.attendance === 'present' &&
          attendanceMonth === currentMonth &&
          attendanceYear === currentYear
        );
      }).length;
    };
    

    const getAbsentDays = (employeeId) => {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      return attendanceData.filter((data) => {
        const attendanceDate = new Date(data.date); // Assuming the date is stored in the 'date' field
        const attendanceMonth = attendanceDate.getMonth();
        const attendanceYear = attendanceDate.getFullYear();
    
        return (
          data.employeeid === employeeId &&
          data.attendance === 'absent' &&
          attendanceMonth === currentMonth &&
          attendanceYear === currentYear
        );
      }).length;
    };
    

    // Function to calculate the total days for a specific employee
    const getTotalDays = (employeeId) => {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
    
      return attendanceData.filter((data) => {
        const attendanceDate = new Date(data.date); // Assuming the date is stored in the 'date' field
        const attendanceMonth = attendanceDate.getMonth();
        const attendanceYear = attendanceDate.getFullYear();
    
        return (
          data.employeeid === employeeId &&
          attendanceMonth === currentMonth &&
          attendanceYear === currentYear
        );
      }).length;
    };
    
  const filteredEmployeeData = employeeData.filter(
    (employee) =>
      employee.fullName.toLowerCase().includes(searchByName.toLowerCase()) &&
      (searchById === '' || employee.employeeId.includes(searchById)) &&
      (searchByPhone === '' || (employee.phoneNumber && employee.phoneNumber.includes(searchByPhone)))
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="dashboard-content-one" id="cont-1">
      <div className="breadcrumbs-area">
        <h3>View Employee Attendance</h3>
      </div>

      <div className="card height-auto">
        <div className="card-body">
          <div className="heading-layout1"></div>

          <form className="mg-b-20">
            <div className="row gutters-8">
              <div className="col-4-xxxl col-xl-4 col-lg-3 col-12 form-group">
                <input
                  type="text"
                  placeholder="Search by Name ..."
                  className="form-control"
                  value={searchByName}
                  onChange={(e) => setSearchByName(e.target.value)}
                />
              </div>
              <div className="col-3-xxxl col-xl-3 col-lg-3 col-12 form-group">
                <input
                  type="text"
                  placeholder="Search by ID ..."
                  className="form-control"
                  value={searchById}
                  onChange={(e) => setSearchById(e.target.value)}
                />
              </div>
              <div className="col-4-xxxl col-xl-3 col-lg-3 col-12 form-group">
                <input
                  type="text"
                  placeholder="Search by Phone ..."
                  className="form-control"
                  value={searchByPhone}
                  onChange={(e) => setSearchByPhone(e.target.value)}
                />
              </div>
              <div className="col-1-xxxl col-xl-2 col-lg-3 col-12 form-group">
                <button
                  type="button"
                  style={{ textAlign: "center" }}
                  className="fw-btn-fill btn-gradient-yellow"
                >
                  SEARCH
                </button>
              </div>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table display data-table text-nowrap">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Full Name</th>
                  <th>Employee ID</th>
                  <th>Phone Number</th>
                  <th>Present days</th>
                  <th>Absent days</th>
                  <th>Total days</th>
                </tr>
              </thead>
              <tbody>
                {filteredEmployeeData.length > 0 ? (
                  filteredEmployeeData.map((employee, index) => (
                    <tr key={employee.id}>
                      <td>{index + 1}</td>
                      <td>{employee.fullName}</td>
                      <td>{employee.employeeId}</td>
                      <td>{employee.phoneNumber || 'N/A'}</td>
                      <td>{getPresentDays(employee.employeeId)}</td>
                      <td>{getAbsentDays(employee.employeeId)}</td>
                      <td>{getTotalDays(employee.employeeId)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
