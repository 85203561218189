import React, { useEffect, useState } from 'react';
import { EMPLOYEE_API_URL } from './apicofig';
export default function AddEmployee() {
  const [isDataEntered, setIsDataEntered] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '0',
    employeeId: '0',
    gender: '0',
    dateOfBirth: '0',
    address: '0',
    phoneNumber: '0',
    emailAddress: '0',
    maritalStatus: '0',
    nationalIdNumber: '0',
    passportInfo: '0',
    emergencyContact: '0',
    bankName: '0', 
    bankAccountNumber: '0',
    accountType: '0', 
    bankBranch: '0', 
    ifscCode: '0', 
    swiftCode: '0', 
    bankAddress: '0',
    micrCode: '0',
    pt: '208', 
    pf: '1800', 
    esi: '0', 
    pfNumber: '0', 
    hra: '0', 
    specialallowance:"0", 
    pan: '0', 
    residencyStatus: '0',
    employmentStartDate: '0',
    employmentStatus: '0',
    jobTitle: '0',
    department: '0',
    reportingManager: '0',
    salary: '0',
    incentive: '0',
    location: '0',
    uan: '0',
    bonous: '0',   
    attendance: '0'    
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    const allDataEntered = Object.values({ ...formData, [name]: value }).every((value) => value.trim() !== '');
    setIsDataEntered(allDataEntered);
  };

  const handleSubmit = async () => {
    try {
      let newdata={
        ...formData,
        hra:((((parseFloat(formData.salary) ||0)/ 100 )* 60).toFixed(2)),
        specialallowance:((((parseFloat(formData.salary) ||0)/ 100 )* 40).toFixed(2))
      }
      const response = await fetch('https://dremerz.net/api/employees/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newdata),
      });

      if (!response.ok) {
        throw new Error('Failed to add data.');
      }

      console.log('Item added successfully!');
      alert('Item added successfully!');
    } catch (error) {
      console.error('Error! Kindly Contact: 9 840 841 840', error);
      alert('Error! Kindly Contact: 9 840 841 840');
    }
  };

  const handleReset = () => {
    setFormData({
      fullName: '0',
      employeeId: '0',
      gender: '0',
      dateOfBirth: '0',
      address: '0',
      phoneNumber: '0',
      emailAddress: '0',
      maritalStatus: '0',
      nationalIdNumber: '0',
      passportInfo: '0',
      emergencyContact: '0',
      bankName: '0', 
      bankAccountNumber: '0',
      accountType: '0', 
      bankBranch: '0', 
      ifscCode: '0', 
      swiftCode: '0', 
      bankAddress: '0',
      micrCode: '0',
      pt: '208', 
      pf: '1800', 
      esi: '0', 
      pfNumber: '0', 
      hra: '0', 
      specialallowance: '0', 
      pan: '0', 
      residencyStatus: '0',
      employmentStartDate: '0',
      employmentStatus: '0',
      jobTitle: '0',
      department: '0',
      reportingManager: '0',
      salary: '0',
      incentive: '0',
      location: '0',
      uan: '0',
      bonous: '0',
    attendance: '0'   
      
    });
    setIsDataEntered(false);
  };

  return (
    <div className="dashboard-content-one" id="cont">
      
      <div className="breadcrumbs-area">
        <h3>Add New Employee</h3>
      </div>
      <div className="card height-auto">
        <div className="card-body">
          <div className="heading-layout1">
            <div className="dropdown">
              <div className="dropdown-menu dropdown-menu-right">
                <a className="dropdown-item" href="#">
                  <i className="fas fa-times text-orange-red" />
                  Close
                </a>
                <a className="dropdown-item" href="#">
                  <i className="fas fa-cogs text-dark-pastel-green" />
                  Edit
                </a>
                <a className="dropdown-item" href="#">
                  <i className="fas fa-redo-alt text-orange-peel" />
                  Refresh
                </a>
              </div>
            </div>
          </div>
          <form className="new-added-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Full Name *</label>
                <input
                  type="text"
                  name="fullName"
                  placeholder=""
                  className="form-control"
                  value={formData.fullName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Employee ID *</label>
                <input
                  type="text"
                  name="employeeId"
                  placeholder=""
                  className="form-control"
                  value={formData.employeeId}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Gender *</label>
                <select
                  className="select2"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender *</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Date Of Birth *</label>
                <input
                  type="date"
                  name="dateOfBirth"
                  placeholder="dd/mm/yyyy"
                  className="form-control air-datepicker"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                />
                <i className="far fa-calendar-alt" />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
  <label>Address *</label>
  <input
    type="text"
    name="address"
    placeholder=""
    className="form-control"
    value={formData.address}
    onChange={handleChange}
  />
</div>
<div className="col-xl-3 col-lg-6 col-12 form-group">
  <label>Location *</label>
  <input
    type="text"
    name="location"
    placeholder=""
    className="form-control"
    value={formData.location}
    onChange={handleChange}
  />
</div>

<div className="col-xl-3 col-lg-6 col-12 form-group">
  <label>Phone Number *</label>
  <input
    type="text"
    name="phoneNumber"
    placeholder=""
    className="form-control"
    value={formData.phoneNumber}
    onChange={handleChange}
  />
</div>

<div className="col-xl-3 col-lg-6 col-12 form-group">
  <label>Email Address *</label>
  <input
    type="text"
    name="emailAddress"
    placeholder=""
    className="form-control"
    value={formData.emailAddress}
    onChange={handleChange}
  />
</div>

              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Marital Status *</label>
                <select
                  className="select2"
                  name="maritalStatus"
                  value={formData.maritalStatus}
                  onChange={handleChange}
                >
                  <option value="">Select Marital Status *</option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Divorced">Divorced</option>
                  <option value="Widowed">Widowed</option>
                </select>
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>National Identification Number</label>
                <input
                  type="text"
                  name="nationalIdNumber"
                  placeholder=""
                  className="form-control"
                  value={formData.nationalIdNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Passport Information</label>
                <input
                  type="text"
                  name="passportInfo"
                  placeholder=""
                  className="form-control"
                  value={formData.passportInfo}
                  onChange={handleChange}
                />
              </div>

              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Emergency Contact *</label>
                <input
                  type="text"
                  name="emergencyContact"
                  placeholder=""
                  className="form-control"
                  value={formData.emergencyContact}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Bank Name *</label>
                <input
                  type="text"
                  name="bankName"
                  placeholder=""
                  className="form-control"
                  value={formData.bankName}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Bank Account Number *</label>
                <input
                  type="text"
                  name="bankAccountNumber"
                  placeholder=""
                  className="form-control"
                  value={formData.bankAccountNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Account Type *</label>
                <input
                  type="text"
                  name="accountType"
                  placeholder=""
                  className="form-control"
                  value={formData.accountType}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Bank Branch *</label>
                <input
                  type="text"
                  name="bankBranch"
                  placeholder=""
                  className="form-control"
                  value={formData.bankBranch}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>IFSC Code *</label>
                <input
                  type="text"
                  name="ifscCode"
                  placeholder=""
                  className="form-control"
                  value={formData.ifscCode}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>SWIFT/BIC Code (for international transactions)</label>
                <input
                  type="text"
                  name="swiftCode"
                  placeholder=""
                  className="form-control"
                  value={formData.swiftCode}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Bank Address *</label>
                <input
                  type="text"
                  name="bankAddress"
                  placeholder=""
                  className="form-control"
                  value={formData.bankAddress}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>MICR Code *</label>
                <input
                  type="text"
                  name="micrCode"
                  placeholder=""
                  className="form-control"
                  value={formData.micrCode}
                  onChange={handleChange}
                />
              </div>


              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Professional Tax (PT)</label>
                <input
                  type="text"
                  name="pt"
                  placeholder=""
                  className="form-control"
                  value={formData.pt}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Provident Fund (PF)</label>
                <input
                  type="text"
                  name="pf"
                  placeholder=""
                  className="form-control"
                  value={formData.pf}
                  onChange={handleChange}
                />
              </div>

              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>(PF) Number</label>
                <input
                  type="text"
                  name="pfNumber"
                  placeholder=""
                  className="form-control"
                  value={formData.pfNumber}
                  onChange={handleChange}
                />
              </div>

              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Pan Number</label>
                <input
                  type="text"
                  name="pan"
                  placeholder=""
                  className="form-control"
                  value={formData.pan}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>UAN Number</label>
                <input
                  type="text"
                  name="uan"
                  placeholder=""
                  className="form-control"
                  value={formData.uan}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Employee State Insurance (ESI)</label>
                <input
                  type="text"
                  name="esi"
                  placeholder=""
                  className="form-control"
                  value={formData.esi}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Citizenship/Residency Status</label>
                <select
                  className="select2"
                  name="residencyStatus"
                  value={formData.residencyStatus}
                  onChange={handleChange}
                >
                  <option value="">Select Status</option>
                  <option value="Citizen">Citizen</option>
                  <option value="Resident">Resident</option>
                  <option value="Work Visa">Work Visa</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Employment Start Date *</label>
                <input
                  type="date"
                  name="employmentStartDate"
                  placeholder="dd/mm/yyyy"
                  className="form-control air-datepicker"
                  value={formData.employmentStartDate}
                  onChange={handleChange}
                />
                <i className="far fa-calendar-alt" />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Employment Status *</label>
                <select
                  className="select2"
                  name="employmentStatus"
                  value={formData.employmentStatus}
                  onChange={handleChange}
                >
                  <option value="">Select Employment Status</option>
                  <option value="Full Time">Full Time</option>
                  <option value="Part Time">Part Time</option>
                  <option value="Contract">Contract</option>
                  <option value="Intern">Intern</option>
                </select>
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Designation *</label>
                <input
                  type="text"
                  name="jobTitle"
                  placeholder=""
                  className="form-control"
                  value={formData.jobTitle}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Department/Team *</label>
                <input
                  type="text"
                  name="department"
                  placeholder=""
                  className="form-control"
                  value={formData.department}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Reporting Manager *</label>
                <input
                  type="text"
                  name="reportingManager"
                  placeholder=""
                  className="form-control"
                  value={formData.reportingManager}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>salary Details *</label>
                <input
                  type="text"
                  name="salary"
                  placeholder=""
                  className="form-control"
                  value={formData.salary}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Conveyance Details *</label>
                <input
                  type="text"
                  name="incentive"
                  placeholder=""
                  className="form-control"
                  value={formData.incentive}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>H.R.A *</label>
                <input
                  type="text"
                  name="hra"
                  placeholder=""
                  className="form-control"
                  value={(((parseFloat(formData.salary) ||0)/ 100 )* 60 ).toFixed(2)}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>SPECIAL ALLOWANCE *</label>
                <input
                  type="text"
                  name="specialallowance"
                  placeholder=""
                  className="form-control"
                  value={ (((parseFloat(formData.salary) ||0)/ 100 )* 40).toFixed(2)}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>BONOUS *</label>
                <input
                  type="text"
                  name="bonous"
                  placeholder=""
                  className="form-control"
                  value={formData.bonous}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-3 col-lg-6 col-12 form-group">
                <label>Absent Days *</label>
                <input
                  type="text"
                  name="attendance"
                  placeholder=""
                  className="form-control"
                  value={formData.attendance}
                  onChange={handleChange}
                />
              </div>
              <div className="col-12 form-group mg-t-8">
                {
                    isDataEntered?
                    <div
                    className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                    onClick={()=>{
                      handleSubmit();
                    }}
                    style={{cursor:"pointer"}}
                  >
                    Save
                  </div>:
                  <div
                 
                  className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                   style={{cursor:"auto",backgroundColor:"#ff9e017b"}}
                  >
                    Save
                  </div>
                }
               
                <div
 onClick={handleReset}
                  className="btn-fill-lg bg-blue-dark btn-hover-yellow"
                >
                  Reset
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
   
  );
}
