import React, { useEffect, useState } from 'react';
import axios from 'axios';

const API_BASE_URL = 'https://dremerz.net/api/attendance/'; // Replace with your API base URL

export default function Takeattendance() {
  const [employeeData, setEmployeeData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://dremerz.net/api/employees/');
        const data = response.data;
        setEmployeeData(data);
      } catch (error) {
        console.error('Error fetching employee data:', error);
        alert('Error fetching employee data. Please try again later.');
      }
    };

    fetchData();
  }, []);

  const handleAttendanceChange = (e, id) => {
    if (e.target.checked) {
      setAttendanceData((prevAttendance) => [...prevAttendance, id]);
    } else {
      setAttendanceData((prevAttendance) => prevAttendance.filter((absentId) => absentId !== id));
    }
  };

  const handleSubmitAttendance = async (e) => {
    e.preventDefault();

    try {
      const date = new Date().toISOString();
      const attendanceDataArray = employeeData.map((employee) => {
        const attendanceStatus = attendanceData.includes(employee.id) ? 'absent' : 'present';
        return {
          name: employee.fullName,
          employeeid: employee.employeeId,
          attendance: attendanceStatus,
          date: date,
        };
      });
     
      for (var i = 0; i < attendanceDataArray.length; i++) {
        console.log(attendanceDataArray[i]);
       await axios.post(API_BASE_URL, attendanceDataArray[i], {
        headers: {
          'Content-Type': 'application/json', // Set the Content-Type header to JSON
        },
      });
      }

      setAttendanceData([]);

      alert('Attendance saved successfully!');
    } catch (error) {
      console.error('Error saving attendance:', error);
      alert('Error saving attendance. Please try again.');
    }
  };


  return (
    <div className="dashboard-content-one" id="cont-1">
      <div className="breadcrumbs-area">
        <h3>Take Employee Attendance</h3>
      </div>

      <div className="card height-auto">
        <div className="card-body">
          <div className="heading-layout1">
          </div>
          <table className="table display data-table text-nowrap">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Full Name</th>
                <th>Employee ID</th>
                <th>Absent</th>
              </tr>
            </thead>
            <tbody>
              {employeeData.length > 0 ? (
                employeeData.map((employee, index) => (
                  <tr key={employee.id}>
                    <td>{index + 1}</td>
                    <td>{employee.fullName}</td>
                    <td>{employee.employeeId}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={attendanceData.includes(employee.id)}
                        onChange={(e) => handleAttendanceChange(e, employee.id)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">Loading...</td>
                </tr>
              )}
            </tbody>
          </table>
          <div style={{ textAlign: "right", alignItems: "right" }}>
            <button onClick={handleSubmitAttendance}>Save Attendance</button>
          </div>
        </div>
      </div>
    </div>
  );
}
