import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function Viewemployee() {
  const [employeeData, setEmployeeData] = useState([]);
  const [searchById, setSearchById] = useState('');
  const [searchByName, setSearchByName] = useState('');
  const [searchByPhone, setSearchByPhone] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://dremerz.net/api/employees/');
        const data = response.data;
        console.log(data)
        setEmployeeData(data); // Set employeeData to the 'results' array
        setLoading(false);
      } catch (error) {
        console.error('Error fetching employee data:', error);
        setError('Error fetching employee data. Please try again later.');
        setLoading(false);
      }
    };
    

    fetchData();
  }, []);

  const handleInputChange = (e, id, field) => {
    const updatedData = employeeData.map((employee) => {
      if (employee.id === id) {
        return { ...employee, [field]: e.target.value };
      }
      return employee;
    });
    setEmployeeData(updatedData);
  };

  const handleUpdate = async (id, updatedData) => {
    try {
      const response = await axios.put(`https://dremerz.net/api/employees/${id}/`, updatedData);
      console.log(response)
      if (response.status === 200) {
        setEmployeeData((prevData) =>
          prevData.map((employee) => (employee.id === id ? { ...employee, ...updatedData } : employee))
        );
        alert('Employee data updated successfully!');
      } else {
        alert('Failed to update employee data.');
      }
    } catch (error) {
      console.error('Error updating employee data:', error);
      alert('Error updating employee data. Please try again.');
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`https://dremerz.net/api/employees/${id}/`);
      if (response.status === 204) {
        setEmployeeData((prevData) => prevData.filter((employee) => employee.id !== id));
        alert('Employee data deleted successfully!');
      } else {
        alert('Failed to delete employee data.');
      }
    } catch (error) {
      console.error('Error deleting employee data:', error);
      alert('Error deleting employee data. Please try again.');
    }
  };

  const handleUpdateAll = async () => {
    try {
      for (const employee of employeeData) {
        const id = employee.id;
        const updatedData = {
          fullName: employee.fullName,
          employeeId: employee.employeeId,
          gender: employee.gender,
          dateOfBirth: employee.dateOfBirth,
          address: employee.address,
          phoneNumber: employee.phoneNumber,
          emailAddress: employee.emailAddress,
          maritalStatus: employee.maritalStatus,
          nationalIdNumber: employee.nationalIdNumber,
          passportInfo: employee.passportInfo,
          emergencyContact: employee.emergencyContact,
          bankName: employee.bankName,
          bankAccountNumber: employee.bankAccountNumber,
          accountType: employee.accountType,
          bankBranch: employee.bankBranch,
          ifscCode: employee.ifscCode,
          swiftCode: employee.swiftCode,
          bankAddress: employee.bankAddress,
          micrCode: employee.micrCode,
          pt: employee.pt,
          pf: employee.pf,
          esi: employee.esi,
          residencyStatus: employee.residencyStatus,
          employmentStartDate: employee.employmentStartDate,
          employmentStatus: employee.employmentStatus,
          jobTitle: employee.jobTitle,
          department: employee.department,
          reportingManager: employee.reportingManager,
          salary: employee.salary,
          incentive: employee.incentive,
          pan: employee.pan,
          uan: employee.uan,
          location: employee.location,
          hra: employee.hra,
          specialallowance: employee.specialallowance,
          bonous: employee.bonous,
          pfNumber: employee.pfNumber,
          attendance: employee.attendance,
        };
        await handleUpdate(id, updatedData);
      }

     
    } catch (error) {
      console.error('Error updating employee data:', error);
      alert('Error updating employee data. Please try again.');
    }
  };

  const filteredData = employeeData.filter((employee) => {
    return (
      employee.employeeId.includes(searchById) &&
      employee.fullName.toLowerCase().includes(searchByName.toLowerCase()) &&
      employee.phoneNumber.includes(searchByPhone)
    );
  });

  return (
    <div className="dashboard-content-one" id="cont-1">
      <div className="breadcrumbs-area">
        <h3>View Employee Details</h3>
      </div>
      <div className="card height-auto">
        <div className="card-body">
          <div className="heading-layout1">
            <form className="nmg-b-20">
              <div className="row gutters-8">
                <div className="col-4-xxxl col-xl-4 col-lg-3 col-12 form-group">
                  <input
                    type="text"
                    placeholder="Search by Name ..."
                    className="form-control"
                    value={searchByName}
                    onChange={(e) => setSearchByName(e.target.value)}
                  />
                </div>
                <div className="col-3-xxxl col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    type="text"
                    placeholder="Search by ID ..."
                    className="form-control"
                    value={searchById}
                    onChange={(e) => setSearchById(e.target.value)}
                  />
                </div>
                <div className="col-4-xxxl col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    type="text"
                    placeholder="Search by Phone ..."
                    className="form-control"
                    value={searchByPhone}
                    onChange={(e) => setSearchByPhone(e.target.value)}
                  />
                </div>
                <div className="col-3-xxxl col-xl-2 col-lg-3 col-12 form-group">
                  <button
                    style={{ textAlign: 'center' }}
                    className="fw-btn-fill btn-gradient-yellow"
                    type="button"
                  >
                    SEARCH
                  </button>
                </div>
              </div>
             
            </form>
            </div>
            {loading && <div>Loading...</div>}
            {error && <div>Error: {error}</div>}
            {!loading && !error && filteredData.length === 0 && (
              <div>No data found.</div>
            )}

            {!loading && !error && filteredData.length > 0 && (
              <div className="table-responsive">
                <table className="table display data-table text-nowrap">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Full Name</th>
                     
                  
                    
                      <th>Bonous Details</th>
                      <th>Absent Days</th>
                      <th>Update</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((employee, index) => (
                      <tr key={employee.id}>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            type="text"
                            value={employee.fullName}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'fullName')
                            }
                          />
                        </td>
               
                     
               

                      
                        <td>
                          <input
                            type="text"
                            value={employee.bonous}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'bonous')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.attendance}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'attendance')
                            }
                          />
                        </td>
                        <td>
                          <button
                            onClick={() =>
                              handleUpdate(employee.id, {
                                fullName: employee.fullName,
                                employeeId: employee.employeeId,
                                gender: employee.gender,
                                dateOfBirth: employee.dateOfBirth,
                                address: employee.address,
                                phoneNumber: employee.phoneNumber,
                                emailAddress: employee.emailAddress,
                                maritalStatus: employee.maritalStatus,
                                nationalIdNumber: employee.nationalIdNumber,
                                passportInfo: employee.passportInfo,
                                emergencyContact: employee.emergencyContact,
                                bankName: employee.bankName,
                                bankAccountNumber: employee.bankAccountNumber,
                                accountType: employee.accountType,
                                bankBranch: employee.bankBranch,
                                ifscCode: employee.ifscCode,
                                swiftCode: employee.swiftCode,
                                bankAddress: employee.bankAddress,
                                micrCode: employee.micrCode,
                                pt: employee.pt,
                                pf:employee.pf,
                                esi: employee.esi,
                                residencyStatus: employee.residencyStatus,
                                employmentStartDate: employee.employmentStartDate,
                                employmentStatus: employee.employmentStatus,
                                jobTitle: employee.jobTitle,
                                department: employee.department,
                                reportingManager: employee.reportingManager,
                                salary: employee.salary,
                                incentive: employee.incentive,
                                pan: employee.pan,
                                uan: employee.uan,
                                location: employee.location,
                                hra:employee.hra,
                                specialallowance: employee.specialallowance,
                                bonous: employee.bonous,
                                pfNumber: employee.pfNumber,
                                attendance: employee.attendance,
                              })
                            }
                          >
                            Update
                          </button>
                        </td>
                        <td>
                          <button onClick={() => handleDelete(employee.id)}>
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td id="tbfooter" colSpan="40">
                        <button onClick={handleUpdateAll}>Update All</button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )}
          </div>
        </div>
      
    </div>
  );
}


