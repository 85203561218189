import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faMoneyBillTransfer, faMoneyBill, faPersonCirclePlus, faFingerprint, faStreetView, faLock } from '@fortawesome/free-solid-svg-icons';
import { Link, Outlet, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const location = useLocation();

  return (
    <div className='dashboard-page-one'>
      <div className="sidebar-main sidebar-menu-one sidebar-expand-md sidebar-color">
        <div className="sidebar-menu-content">
          <ul className="nav nav-sidebar-menu sidebar-toggle-view">
            <li className="nav-item sidebar-nav-item">
              <Link className="nav-link" to='/ADD-EMPLOYEE'>
                <FontAwesomeIcon icon={faPersonCirclePlus} style={{ color: "#ffa404" }} /> &nbsp;&nbsp;
                Add New Employee
              </Link>
            </li>
            <li className="nav-item sidebar-nav-item">
              <Link className="nav-link" to='/VIEW-EMPLOYEE'>
                <FontAwesomeIcon icon={faPeopleGroup} style={{ color: "#ffa404" }} /> &nbsp;&nbsp;
                View Employee Details
              </Link>
            </li>
            <li className="nav-item sidebar-nav-item">
              <Link className="nav-link" to='/VIEW-PAYINFO'>
              <FontAwesomeIcon icon={faStreetView} style={{ color: '#ffa404' }} /> &nbsp;&nbsp;
               Attendance Details
              </Link>
            </li>
            <li className="nav-item sidebar-nav-item">
              <Link className="nav-link" to='/VIEW-NETSALARY'>
              <FontAwesomeIcon icon={faMoneyBill} style={{ color: "#ffa404" }} /> &nbsp;&nbsp;
               View Net Salary
              </Link>
            </li>
            
            <li className="nav-item sidebar-nav-item">
              <Link className="nav-link" to='/SALARYANDTAX'>
                <FontAwesomeIcon icon={faMoneyBill} style={{ color: "#ffa404" }} /> &nbsp;&nbsp;
                Salary & Tax Details
              </Link>
            </li>
            <li className="nav-item sidebar-nav-item">
              <span className="nav-link" >
                <FontAwesomeIcon icon={faFingerprint} style={{ color: "#ffa404" }} /> &nbsp;&nbsp;
                <span>Take Attendance</span> &nbsp;&nbsp;<FontAwesomeIcon  icon={faLock}/>
              </span>
            </li>
            <li className="nav-item sidebar-nav-item">
              <span className="nav-link">
                <FontAwesomeIcon icon={faStreetView} style={{ color: '#ffa404' }} /> &nbsp;&nbsp;
                <span>View Attendance </span> &nbsp;&nbsp;<FontAwesomeIcon  icon={faLock}/>
              </span>
            </li>
            <li className="nav-item sidebar-nav-item">
              <span className="nav-link" >
                <FontAwesomeIcon icon={faMoneyBillTransfer} style={{ color: "#ffa404" }} /> &nbsp;&nbsp;
                <span>Direct Deposit</span> &nbsp;&nbsp;<FontAwesomeIcon icon={faLock} />
              </span>
            </li>
            {/* Add other sidebar items here */}
          </ul>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Sidebar;
