// import React, { useEffect, useState } from "react";
// import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

// import Navbar from "./Navbar";
// import Sidebar from "./Sidebar";
// import Addemployee from "./Addemployee";
// import Viewemployee from "./Viewemployee";
// import Takeattendance from "./takeattendance";
// import Viewattendance from "./Viewattendance";
// import SalaryAndtax from "./SalaryAndtax";
// import MakeSimple from "./MakeSimple";
// import Netsalary from "./Netsalary";

// function App() {
//   const handleMouseEnter = () => {
//     setIsHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovered(false);
//   };
//   const [authenticated, setAuthenticated] = useState(false);
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   const [isHovered, setIsHovered] = useState(false);

//   const handleSubmit = (event) => {
//     event.preventDefault();

//     // Replace this with your actual authentication logic
//     const validUsername = "SamHyperBusiness";
//     const validPassword = "Sam@0987";

//     if (username === validUsername && password === validPassword) {
//       setAuthenticated(true);
//       // Store the authentication status in localStorage
//       localStorage.setItem("authenticated", "true");
//     } else {
//       alert("Invalid username or password.");
//     }
//   };

//   const handleLogout = () => {
//     setAuthenticated(false);
//     // Remove the authentication status from localStorage
//     localStorage.removeItem("authenticated");
//     // Optionally, you can clear the username and password from state as well
//     setUsername("");
//     setPassword("");
//   };

//   // Check if the user is already authenticated on initial load
//   useEffect(() => {
//     const isUserAuthenticated = localStorage.getItem("authenticated");
//     if (isUserAuthenticated === "true") {
//       setAuthenticated(true);
//     }
//   }, []);

//   return (
//     <div>
//       {!authenticated ? (
//         // Render the login form if not authenticated
//         <div className="login-page-wrap">
//           <div className="login-page-content">
//             <div className="login-box">
//               <form onSubmit={handleSubmit} className="login-form">
//                 <div className="form-group">
//                   <label>Username</label>
//                   <input
//                     type="text"
//                     placeholder="Enter username"
//                     className="form-control"
//                     value={username}
//                     onChange={(e) => setUsername(e.target.value)}
//                   />
//                   <i className="far fa-envelope" />
//                 </div>
//                 <div className="form-group">
//                   <label>Password</label>
//                   <input
//                     type="password"
//                     placeholder="Enter password"
//                     className="form-control"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                   />
//                   <i className="fas fa-lock" />
//                 </div>
//                 <div className="form-group">
//                   <button type="submit" className="login-btn">
//                     Login
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       ) : (
//         // Render the content after successful authentication
//         <>
//         <>
//         <div className="centered-container">
//   <div className="post-buttons">
//     <a
//       href="https://payroll.samhyperbusiness.org/"
//       target="_blank"
//       rel="noopener noreferrer"
//       className="post-button"
//       style={{color: "white", }}
//     >
//       <span className="button-icon">&#128176;</span> Payroll Software
//     </a>
//     <a
//       href="https://offerletter.samhyperbusiness.org/"
//       target="_blank"
//       rel="noopener noreferrer"
//       className="post-button"
//       style={{color: "white", }}
//     >
//       <span className="button-icon">&#128221;</span> Offer Letter Software
//     </a>

//     <a
//       href="https://confirmationletter.samhyperbusiness.org/"
//       target="_blank"
//       rel="noopener noreferrer"
//       className="post-button"
//       style={{color: "white", }}
//     >
//       <span className="button-icon">&#128999;
      
//       </span> Confirmation Letter Software
//     </a>


//   </div>
// </div>


//         </>
//         </>
//       )}
//     </div>
//   );
// }

// export default App;










import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Addemployee from "./Addemployee";
import Viewemployee from "./Viewemployee";
import Takeattendance from "./takeattendance";
import Viewattendance from "./Viewattendance";
import SalaryAndtax from "./SalaryAndtax";
import MakeSimple from "./MakeSimple";
import Netsalary from "./Netsalary";

function App() {
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Replace this with your actual authentication logic
    const validUsername = "SamHyperBusiness";
    const validPassword = "Sam@0987";

    if (username === validUsername && password === validPassword) {
      setAuthenticated(true);
      // Store the authentication status in localStorage
      localStorage.setItem("authenticated", "true");
    } else {
      alert("Invalid username or password.");
    }
  };

  const handleLogout = () => {
    setAuthenticated(false);
    // Remove the authentication status from localStorage
    localStorage.removeItem("authenticated");
    // Optionally, you can clear the username and password from state as well
    setUsername("");
    setPassword("");
  };

  // Check if the user is already authenticated on initial load
  useEffect(() => {
    const isUserAuthenticated = localStorage.getItem("authenticated");
    if (isUserAuthenticated === "true") {
      setAuthenticated(true);
    }
  }, []);

  return (
    <div>
      {!authenticated ? (
        // Render the login form if not authenticated
        <div className="login-page-wrap">
          <div className="login-page-content">
            <div className="login-box">
              <form onSubmit={handleSubmit} className="login-form">
                <div className="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    placeholder="Enter username"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <i className="far fa-envelope" />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="Enter password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <i className="fas fa-lock" />
                </div>
                <div className="form-group">
                  <button type="submit" className="login-btn">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        // Render the content after successful authentication
        <>
          <BrowserRouter>
          <div className="navbar navbar-expand-md header-menu-one bg-light">
      <div className="nav-bar-header-one">
        <div className="header-logo">
          <div>
            <img src="img/dremerz-transparent-logo.png"  width={"180px"} alt="logo" />
          </div>
        </div>
        <div className="toggle-button sidebar-toggle">
          <button type="button" className="item-link">
            <span className="btn-icon-wrap">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </div>
      </div>
      <div className="d-md-none mobile-nav-bar">
         
        <button type="button" className="navbar-toggler sidebar-toggle-mobile">
          <i className="fas fa-bars"></i>
        </button>
      </div>
      <div className="header-main-menu collapse navbar-collapse" id="mobile-navbar">
        <ul  id='navbar-nav'>
        <div id='sam-hyper-business' >Sam Hyper Business Private Limited</div>
        </ul>
        <ul className="navbar-nav">
          <li className="navbar-item dropdown header-admin">
            <div className="navbar-nav-link dropdown-toggle"  data-toggle="dropdown" aria-expanded="false">
              <div className="admin-title">
                <h5 className="item-title">S.H.B</h5>
                <span
                    style={{
                      cursor: "pointer",
                      color: isHovered ? "red" : "black",
                    }}
                    onClick={handleLogout}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Click to Log Out
                  </span>
              </div>
              <div className="admin-img">
                <img src="img/figure/admin.jpg" alt="Admin" />
              </div>
            </div>
            <div className="dropdown-menu dropdown-menu-right">
              <div className="item-header">
                <h6 className="item-title">Steven Zone</h6>
              </div>
              
            </div>
          </li>
          {/* Add other navbar items here */}
        </ul>
      </div>
    </div>
  
            
            <Sidebar />

          
            <Routes>
            <Route index element={<Viewemployee />} />
            <Route path="ADD-EMPLOYEE" element={<Addemployee />} />
            <Route path="VIEW-EMPLOYEE" element={<Viewemployee />} />
            <Route path="TAKE-ATTENDANCE" element={<Takeattendance />} />
            <Route path="VIEW-ATTENDANCE" element={<Viewattendance />} />
            <Route path="SALARYANDTAX" element={<SalaryAndtax />} />
            <Route path="VIEW-PAYINFO" element={<MakeSimple></MakeSimple>} />
            <Route path="VIEW-NETSALARY" element={<Netsalary></Netsalary>} />
            <Route path="*" element={<Viewemployee />} />
          </Routes>
          </BrowserRouter>
        </>
      )}
    </div>
  );
}

export default App;
