import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function Viewemployee() {
  const [employeeData, setEmployeeData] = useState([]);
  const [searchById, setSearchById] = useState('');
  const [searchByName, setSearchByName] = useState('');
  const [searchByPhone, setSearchByPhone] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {


    const fetchData = async () => {
      try {
        const response = await axios.get('https://dremerz.net/api/employees/');
        const data = response.data;
        console.log(data)
        setEmployeeData(data); // Set employeeData to the 'results' array
        setLoading(false);
      } catch (error) {
        console.error('Error fetching employee data:', error);
        setError('Error fetching employee data. Please try again later.');
        setLoading(false);
      }
    };
  


    fetchData();
  }, []);

  const handleInputChange = (e, id, field) => {
    const updatedData = employeeData.map((employee) => {
      if (employee.id === id) {
        return { ...employee, [field]: e.target.value };
      }
      return employee;
    });
    setEmployeeData(updatedData);
  };

  const handleUpdate = async (id, updatedData) => {
    console.log(id)
    try {
      const response = await axios.put(`https://dremerz.net/api/employees/${id}/`, updatedData);
      console.log(response)
      if (response.status === 200) {
        setEmployeeData((prevData) =>
          prevData.map((employee) => (employee.id === id ? { ...employee, ...updatedData } : employee))
        );
        alert('Employee data updated successfully!');
      } else {
        alert('Failed to update employee data.');
      }
    } catch (error) {
      console.error('Error updating employee data:', error);
      alert('Error updating employee data. Please try again.');
    }
  };

  const handleDelete = async (id) => {

    try {
      const response = await axios.delete(`https://dremerz.net/api/employees/${id}/`);
      if (response.status === 204) {
        setEmployeeData((prevData) => prevData.filter((employee) => employee.id !== id));
        alert('Employee data deleted successfully!');
      } else {
        alert('Failed to delete employee data.');
      }
    } catch (error) {
      console.error('Error deleting employee data:', error);
      alert('Error deleting employee data. Please try again.');
    }
  };

  const handleUpdateAll = async () => {
    try {
      for (const employee of employeeData) {
        const id = employee.id;
        const updatedData = {
          fullName: employee.fullName,
          employeeId: employee.employeeId,
          gender: employee.gender,
          dateOfBirth: employee.dateOfBirth,
          address: employee.address,
          phoneNumber: employee.phoneNumber,
          emailAddress: employee.emailAddress,
          maritalStatus: employee.maritalStatus,
          nationalIdNumber: employee.nationalIdNumber,
          passportInfo: employee.passportInfo,
          emergencyContact: employee.emergencyContact,
          bankName: employee.bankName,
          bankAccountNumber: employee.bankAccountNumber,
          accountType: employee.accountType,
          bankBranch: employee.bankBranch,
          ifscCode: employee.ifscCode,
          swiftCode: employee.swiftCode,
          bankAddress: employee.bankAddress,
          micrCode: employee.micrCode,
          pt: employee.pt,
          pf: employee.pf,
          esi: employee.esi,
          residencyStatus: employee.residencyStatus,
          employmentStartDate: employee.employmentStartDate,
          employmentStatus: employee.employmentStatus,
          jobTitle: employee.jobTitle,
          department: employee.department,
          reportingManager: employee.reportingManager,
          salary: employee.salary,
          incentive: employee.incentive,
          pan: employee.pan,
          uan: employee.uan,
          location: employee.location,
          hra: employee.hra,
          specialallowance: employee.specialallowance,
          bonous: employee.bonous,
          pfNumber: employee.pfNumber,
          attendance: employee.attendance,
        };
        await handleUpdate(id, updatedData);
      }

     
    } catch (error) {
      console.error('Error updating employee data:', error);
      alert('Error updating employee data. Please try again.');
    }
  };

  const filteredData = employeeData.filter((employee) => {
    return (
      employee.employeeId.includes(searchById) &&
      employee.fullName.toLowerCase().includes(searchByName.toLowerCase()) &&
      employee.phoneNumber.includes(searchByPhone)
    );
  });

  return (
    <div className="dashboard-content-one" id="cont-1">
      <div className="breadcrumbs-area">
        <h3>View Employee Details</h3>
      </div>
      <div className="card height-auto">
        <div className="card-body">
          <div className="heading-layout1">
            <form className="nmg-b-20">
              <div className="row gutters-8">
                <div className="col-4-xxxl col-xl-4 col-lg-3 col-12 form-group">
                  <input
                    type="text"
                    placeholder="Search by Name ..."
                    className="form-control"
                    value={searchByName}
                    onChange={(e) => setSearchByName(e.target.value)}
                  />
                </div>
                <div className="col-3-xxxl col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    type="text"
                    placeholder="Search by ID ..."
                    className="form-control"
                    value={searchById}
                    onChange={(e) => setSearchById(e.target.value)}
                  />
                </div>
                <div className="col-4-xxxl col-xl-3 col-lg-3 col-12 form-group">
                  <input
                    type="text"
                    placeholder="Search by Phone ..."
                    className="form-control"
                    value={searchByPhone}
                    onChange={(e) => setSearchByPhone(e.target.value)}
                  />
                </div>
                <div className="col-3-xxxl col-xl-3 col-lg-3 col-12 form-group">
                  <button
                    style={{ textAlign: 'center' }}
                    className="fw-btn-fill btn-gradient-yellow"
                    type="button"
                  >
                    SEARCH
                  </button>
                </div>
              </div>
             
            </form>
            </div>
            {loading && <div>Loading...</div>}
            {error && <div>Error: {error}</div>}
            {!loading && !error && filteredData.length === 0 && (
              <div>No data found.</div>
            )}

            {!loading && !error && filteredData.length > 0 && (
              <div className="table-responsive">
                <table className="table display data-table text-nowrap">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Full Name</th>
                      <th>Employee ID</th>
                      <th>Gender</th>
                      <th>Date of Birth</th>
                      <th>Address</th>
                      <th>Location</th>
                      <th>Phone Number</th>
                      <th>Email Address</th>
                      <th>Marital Status</th>
                      <th>National ID Number</th>
                      <th>Passport Info</th>
                      <th>Emergency Contact</th>
                      <th>Bank Name</th>
                      <th>Bank Account Number</th>
                      <th>Account Type</th>
                      <th>Bank Branch</th>
                      <th>IFSC Code</th>
                      <th>SWIFT Code</th>
                      <th>Bank Address</th>
                      <th>MICR Code</th>
                      <th>PT</th>
                      <th>PF</th>
                      <th>ESI</th>
                      <th>PF NUMBER</th>
                      <th>PAN NUMBER</th>
                      <th>UAN NUMBER</th>
                      <th>Residency Status</th>
                      <th>Employment Start Date</th>
                      <th>Employment Status</th>
                      <th>Designation</th>
                      <th>Department</th>
                      <th>Reporting Manager</th>
                      <th>Salary Details</th>
                      <th>Conveyance Details</th>
                      <th>HRA Details</th>
                      <th>Special Allowance Details</th>
                      <th>Bonous Details</th>
                      <th>Absent Days</th>
                      <th>Update</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((employee, index) => (
                      <tr key={employee.id}>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            type="text"
                            value={employee.fullName}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'fullName')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.employeeId}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'employeeId')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.gender}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'gender')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.dateOfBirth}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'dateOfBirth')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.address}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'address')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.location}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'location')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.phoneNumber}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'phoneNumber')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.emailAddress}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'emailAddress')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.maritalStatus}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'maritalStatus')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.nationalIdNumber}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'nationalIdNumber')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.passportInfo}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'passportInfo')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.emergencyContact}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'emergencyContact')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.bankName}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'bankName')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.bankAccountNumber}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'bankAccountNumber')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.accountType}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'accountType')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.bankBranch}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'bankBranch')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.ifscCode}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'ifscCode')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.swiftCode}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'swiftCode')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.bankAddress}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'bankAddress')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.micrCode}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'micrCode')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.pt}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'pt')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.pf}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'pf')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.esi}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'esi')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.pfNumber}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'pfNumber')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.pan}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'pan')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.uan}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'uan')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.residencyStatus}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'residencyStatus')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.employmentStartDate}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'employmentStartDate')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.employmentStatus}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'employmentStatus')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.jobTitle}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'jobTitle')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.department}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'department')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.reportingManager}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'reportingManager')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.salary}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'salary')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.incentive}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'incentive')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled
                            value={employee.hra}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'hra')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            disabled
                            value={employee.specialallowance}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'specialallowance')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.bonous}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'bonous')
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={employee.attendance}
                            onChange={(e) =>
                              handleInputChange(e, employee.id, 'attendance')
                            }
                          />
                        </td>
                        <td>
                          <button
                            onClick={() =>
                              handleUpdate(employee.id, {
                                fullName: employee.fullName,
                                employeeId: employee.employeeId,
                                gender: employee.gender,
                                dateOfBirth: employee.dateOfBirth,
                                address: employee.address,
                                phoneNumber: employee.phoneNumber,
                                emailAddress: employee.emailAddress,
                                maritalStatus: employee.maritalStatus,
                                nationalIdNumber: employee.nationalIdNumber,
                                passportInfo: employee.passportInfo,
                                emergencyContact: employee.emergencyContact,
                                bankName: employee.bankName,
                                bankAccountNumber: employee.bankAccountNumber,
                                accountType: employee.accountType,
                                bankBranch: employee.bankBranch,
                                ifscCode: employee.ifscCode,
                                swiftCode: employee.swiftCode,
                                bankAddress: employee.bankAddress,
                                micrCode: employee.micrCode,
                                pt: employee.pt,
                                pf:employee.pf,
                                esi: employee.esi,
                                residencyStatus: employee.residencyStatus,
                                employmentStartDate: employee.employmentStartDate,
                                employmentStatus: employee.employmentStatus,
                                jobTitle: employee.jobTitle,
                                department: employee.department,
                                reportingManager: employee.reportingManager,
                                salary: employee.salary,
                                incentive: employee.incentive,
                                pan: employee.pan,
                                uan: employee.uan,
                                location: employee.location,
                                hra:((((parseFloat(employee.salary) ||0)/ 100 )* 60).toFixed(2)),
                                specialallowance: ((((parseFloat(employee.salary) ||0)/ 100 )* 40).toFixed(2)),
                                bonous: employee.bonous,
                                pfNumber: employee.pfNumber,
                                attendance: employee.attendance,
                              })
                            }
                          >
                            Update
                          </button>
                        </td>
                        <td>
                          <button onClick={() => handleDelete(employee.id)}>
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td id="tbfooter" colSpan="40">
                        <button onClick={handleUpdateAll}>Update All</button>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )}
          </div>
        </div>
      
    </div>
  );
}


